import React from "react";
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import {normalizePath} from '../../utils/get-url-path';

export const Item = ({classNameli, name, title, uri}) => {
    return (
        <>
            {(name || title) &&
            <li
                className={classNameli}>
                {!!uri ?
                    <AniLink to={normalizePath(uri)}
                             paintDrip
                             duration={1}
                             hex="#ed5d2b"
                             className={'tag-link'}>
                        {name ? name : title}
                    </AniLink> :
                    <span>{name ? name : title}</span>
                }
            </li>}
        </>
    )
}
