import React, {useState} from 'react';
import BrandsLists from "../brands";
import FilterElements from "../case-study-filter/filter-elements";

const FilterSort = ({data}) => {
    const {dataFilter, filterList} = data;

    return (
        <>
            <div className={'orange tooltip-popup scale-up-tl flex-container-nowrap__sb'}>
                {!!filterList && Object.keys(filterList).map((entity) => (
                        (entity === 'brandIndustries') ?
                            <div className={'filter-result-wrapper white'}
                                 key={entity}>
                                <FilterElements data={filterList[entity]} title={'Industries'}/>
                            </div> :
                            (entity === 'brandRegions') ?
                                <div className={'filter-result-wrapper white'}
                                     key={entity}>
                                    <FilterElements data={filterList[entity]} title={'Regions'}/>
                                </div> :
                                false
                    )
                )}
            </div>
            <BrandsLists data={dataFilter}/>
        </>
    )
};

const BrandsFilterList = ({data, filterList}) => {
    const {nodes} = data;
    const [dataFilter, setDataFilter] = useState({nodes});

    return (
        <>
            <div className={`tooltip-popup-wrapper`}>
                <FilterSort
                    data={{dataFilter, setDataFilter, filterList}}/>
            </div>
        </>
    )
}

export default BrandsFilterList;
