import React from "react";
import { Item } from './Item'

const Tags = ({ data, classNameUl, classNameli }) => {
    return (
        <>
            {Array.isArray(data) &&
            data.length &&
            <ul className={`tags ${!!classNameUl ? classNameUl : ''}`}>
                {data.map((page) => (
                    <Item
                        key={page?.id}
                        classNameli={classNameli}
                        name={page?.name}
                        title={page?.title}
                        uri={page?.uri}
                    />
                ))}
            </ul>}
        </>
    )
}

export default Tags

