import React, { useState, useEffect, useRef } from 'react';
import sanitizeHtml from 'sanitize-html';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { normalizePath } from '../../utils/get-url-path';
import { OptionList } from '../case-study/options-list';
import Tags from '../tags';
import Img from 'gatsby-image';
import sanitizeOptions from '../../helper/sanitizeOptions';
import useDebounce from '../../hooks/useDebounce';
import { motion, AnimatePresence } from "framer-motion";
import useWindowSize from "../../hooks/useWindowSize";
import * as constants from "../../helper/constants";

const BrandsLists = ({ data }) => {
    const sanitizeOpt = sanitizeOptions();

    const urlText = (data) => data.replace(/(^\w+:|^)\/\//, '');

    const Item = ({ page, item }) => {
        const [hover, setHover] = useState(false);

        const windowSize = useWindowSize();

        const isMobile = windowSize.width < constants.MOBILE_WIDTH;

        const [hoverTerms, setHoverTerms] = useState(false);

        const [animationEnd, setAnimationEnd] = useState(false);

        const [mousePosition, setMousePosition] = useState(null);

        const tempMousePosition = useRef(null);

        const imageEl = useRef(null);

        const onAnimationEnd = () => {
            setAnimationEnd(true);
        };

        const debouncedHoverTerm = useDebounce(hoverTerms, 500);

        useEffect(() => {
            debouncedHoverTerm ? setHover(true) : setHover(false);

            if (animationEnd && mousePosition && hover) {
                if (tempMousePosition.current == null) {
                    tempMousePosition.current = mousePosition;
                }

                const imageWidth = Math.round(imageEl.current.getBoundingClientRect().width);

                if ((mousePosition.x - imageWidth / 2) > tempMousePosition.current.x) {
                    imageEl.current.style.transform = `translateX(30%) translateY(-50%) rotate(2deg)`;
                    imageEl.current.style.transition = `transform 0.75s ease-in-out`;
                    tempMousePosition.current = mousePosition;
                } else if ((mousePosition.x + imageWidth / 2) < tempMousePosition.current.x) {
                    imageEl.current.style.transform = `translateX(50%) translateY(-50%) rotate(-8deg)`;
                    imageEl.current.style.transition = `transform 0.75s ease-in-out`;

                    tempMousePosition.current = mousePosition;
                }
            }

        }, [animationEnd, mousePosition, hover, debouncedHoverTerm]);

        useEffect(() => {
            if (!hover) {
                setAnimationEnd(false);
                setMousePosition(null);
                tempMousePosition.current = null;
                if (!!imageEl?.current?.style) {
                    imageEl.current.style = null;
                }
            }

        }, [hover]);

        const handleMouseMove = ev => {
            setMousePosition({ x: ev.pageX, y: ev.pageY });
        };

        const TagsElement = () => {
            return (
                <div className={'flex-item portfolio-tags'}>
                    <Tags data={page?.tags?.nodes}
                          classNameUl={'flex-container tags-list'}
                          classNameli={'tags-item flex-item paragraph-primary__small'}/>
                </div>
            );
        };

        return (
            <li className={`portfolio-item flex-container-nowrap__sb item visible active ${hover ? 'hover' : ''}`}
                role="presentation"
                onMouseEnter={() => setHoverTerms(true)}
                onMouseLeave={() => setHoverTerms(false)}
                onMouseMove={(ev) => handleMouseMove(ev)}>
                {(item < 1) && (
                    <div className={'portfolio-separator'}/>
                )}
                <div className={'container'}>
                    <div className={'flex-item portfolio-title'}>
                        {!!page?.portfolioIndustries?.nodes.length && (
                            <OptionList data={page.portfolioIndustries}
                                        className={'gray'}/>
                        )}
                        {!!page?.brandsItemsCustomFields?.brandName && (
                            <h2 className={'h2-title major-title black'}>
                                <p className={'text'}>
                                    {page.brandsItemsCustomFields.brandName}
                                </p>
                            </h2>
                        )}
                    </div>
                    {!!page?.tags &&

                    isMobile ? (
                        <>
                            <AnimatePresence initial={true}>
                                    <motion.section
                                        key="content"
                                        initial="collapsed"
                                        animate="open"
                                        exit="collapsed"
                                        variants={{
                                            open: { opacity: 1, height: "auto" },
                                            collapsed: { opacity: 0, height: 0 }
                                        }}
                                        transition={{ duration: 0.45, ease: [0.04, 0.62, 0.23, 0.98] }}
                                    >
                                        <motion.div
                                            variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
                                            transition={{ duration: 0.45 }}
                                        >
                                            <TagsElement/>
                                        </motion.div>
                                    </motion.section>
                            </AnimatePresence>
                        </>
                    ) : <TagsElement/>
                    }

                    <div className={'flex-item portfolio-description paragraph-primary white mobile-description'}>
                        {!!page?.brandsItemsCustomFields?.brandName && (
                            <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(page.brandsItemsCustomFields.shortDescription, sanitizeOpt.title) }}/>
                        )}
                        {!!page?.portfolioRegions && (
                            <OptionList data={page.portfolioRegions}
                                        className={'gray'}
                                        classNameUl={'regions-list'}
                                        classNameli={'regions-item'}/>
                        )}
                    </div>
                    {(!!page?.projectInfo?.projectUrl || page?.brandsItemsCustomFields?.caseStudy?.post?.uri) && (
                        <div className={'portfolio-links-container'}>
                            {!!page?.projectInfo?.projectUrl && (
                                <div className={'flex-item portfolio-link'}>
                                    <a href={page.projectInfo.projectUrl}
                                       target='_blank'
                                       rel='noreferrer'
                                       className={'h5-title orange project-link'}>
                                        {urlText(page.projectInfo.projectUrl)}
                                    </a>
                                </div>
                            )}
                            {!!page?.brandsItemsCustomFields?.caseStudy?.post?.uri && (
                                <div className={'flex-item portfolio-link case-study'}>
                                    <AniLink to={normalizePath(page.brandsItemsCustomFields.caseStudy.post.uri)}
                                             paintDrip
                                             duration={1}
                                             hex="#ed5d2b"
                                             className={'h5-title white project-link'}>
                                        {urlText(page.brandsItemsCustomFields.caseStudy.text)}
                                    </AniLink>
                                </div>
                            )}
                        </div>
                    )}
                    {!!page?.featuredImage?.node?.remoteFile?.childImageSharp?.fluid && (
                        <div className={'portfolio-image'}
                             onAnimationEnd={onAnimationEnd}
                             ref={imageEl}
                        >
                            <Img
                                fluid={
                                    page.featuredImage.node.remoteFile.childImageSharp.fluid
                                }
                            />
                        </div>
                    )}
                </div>
                <div className={'portfolio-separator'}/>
            </li>
        )
    };

    return (
        <>
            <ul className={'portfolio-list flex-col items-list'}>
                {!!data?.nodes && data.nodes.map((page, item) => (
                    page && <Item
                        page={page}
                        key={page.id}
                        item={item}/>
                ))}
            </ul>
        </>
    );
};

export default BrandsLists;
